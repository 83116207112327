<template>
  <div class="page bg-white" id="reorganize">
    <div>
      <el-descriptions :title="inputForm.jobName" :column="4" class="m_b1">
        <el-descriptions-item label="标注模型">{{ getModelIdName(inputForm.modelId) }}</el-descriptions-item>
        <el-descriptions-item label="开始时间">{{ inputForm.startTime }}</el-descriptions-item>
        <el-descriptions-item label="结束时间">{{ inputForm.endTime }}</el-descriptions-item>
        <el-descriptions-item label="启动时间">{{ inputForm.startUpTime }}</el-descriptions-item>
        <el-descriptions-item label="任务描述">{{ inputForm.remake }}</el-descriptions-item>
      </el-descriptions>

      <el-row :gutter="10">
        <el-col :span="6">
          <el-table
              :data="dataList"
              v-loading="loading"
              size="small"
              height="calc(100vh - 320px)"
              ref="table" @row-click="selRow" highlight-current-row>
            <el-table-column prop="inputResourceName" label="图片列表" show-overflow-tooltip>
              <!--                        <template slot-scope="scope">-->
              <!--                            <div class="flex_l_c">-->
              <!--                                <div class="imgName omit">{{ scope.row.name }}</div>-->
              <!--                                <div class="el-icon-time" v-if="scope.row.sbState == 1"></div>-->
              <!--                                <div class="el-icon-circle-check" v-if="scope.row.sbState == 2"></div>-->
              <!--                                <div class="el-icon-warning" v-if="scope.row.sbState == 3"></div>-->
              <!--                            </div>-->
              <!--                        </template>-->

              <!--                        <template slot-scope="scope">-->
              <!--                            <div class="flex_a_c">-->
              <!--                                <el-image-->
              <!--                                        style="width: 36px; height: 36px"-->
              <!--                                        :src="scope.row.inputResource"-->
              <!--                                        :fit="'cover'"-->
              <!--                                >-->
              <!--                                    <div slot="error" class="image-slot">-->
              <!--                                        <el-image-->
              <!--                                                style="width: 36px; height: 36px"-->
              <!--                                                :src="require('@/assets/img/default.png')"-->
              <!--                                                :fit="'cover'">-->
              <!--                                        </el-image>-->
              <!--                                    </div>-->
              <!--                                </el-image>-->
              <!--                            </div>-->
              <!--                        </template>-->
            </el-table-column>
          </el-table>
          <!--                <el-pagination-->
          <!--                        @size-change="sizeChangeHandle"-->
          <!--                        @current-change="currentChangeHandle"-->
          <!--                        :current-page="pageNo"-->
          <!--                        :page-sizes="[10, 20, 50, 100]"-->
          <!--                        :page-size="pageSize"-->
          <!--                        :total="total"-->
          <!--                        background-->
          <!--                        layout="total, prev, pager, next">-->
          <!--                </el-pagination>-->
        </el-col>
        <el-col :span="18">
          <div ref="page_canvas" class="labelImg"></div>
          <div class="flex_c_c m_t2" v-if="dataList.length">
            <el-button type="primary" v-if="selImg.sbState == 0 || selImg.sbState == 3"
                       @click="submitAudit()"
                       size="small">提交审核
            </el-button>
            <div class="flex_c_c">
              <div v-if="!imgEditType" style="margin-left: 10px">
                <el-button type="primary" @click="startAnnotation(1)" size="small"
                           :disabled="selImg.sbState == 1 || selImg.sbState == 2">多边形标注
                </el-button>
                <el-button type="primary" @click="startAnnotation(2)" size="small"
                           :disabled="selImg.sbState == 1 || selImg.sbState == 2">正方形标注
                </el-button>
                <!--                            <el-input style="width: 130px;margin: 0 10px" size="small" clearable v-model.trim="keyWorld"-->
                <!--                                      placeholder="请输入关键字"></el-input>-->
                <!--                            <el-button type="primary" @click="machineLabeling()" size="small"-->
                <!--                                       :disabled="selImg.sbState == 1 || selImg.sbState == 2">机器标注-->
                <!--                            </el-button>-->
              </div>
              <div style="margin-left: 10px" v-else>
                <el-button type="primary" @click="saveDimension()" size="small">保存标注</el-button>
                <el-button type="danger" @click="noStartAnnotation(1)" size="small">取消标注</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="text_center">
      <el-button size="small" @click="returnPage()">关闭</el-button>
    </div>
  </div>
</template>

<script>
import * as PIXI from 'pixi.js'

export default {
  name: "label",
  data() {
    return {
      id: '',
      inputForm: {},
      modelLabelList: [],

      selImg: {}, //列表选中的数据
      imgEditType: false, //是否展示标注按钮
      dataList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: false,

      imgWidth: '', //图片原始宽度
      imgHeight: '', //图片原始高度
      imgNewWidth: '',//图片现在的宽度
      imgNewHeight: '',//图片现在的高度
      canvasFather: '', //画布父容器dom

      iconWidth: 24, //图标宽度
      iconHeight: 24, //图标高度

      app: '', //pixi 渲染器
      loader: '',//纹理
      sprite: '', //sprite
      scaleBtn: '', //缩放按钮
      container: '', //container
      containerList: '',//创建圈定范围
      circularList: '',//范围顶点信息
      //manifest 加载到纹理缓存使用的资源(需要加载的所有图片)
      manifest: [
        {
          name: 'mapUrl',
          url: '', //图片
        },
        {
          name: "scale_btn",
          url: require("@/assets/img/scale.png"),
        },
      ],

      startPoint: {
        // x,y 记录点击的x，y坐标
        x: null,
        y: null,
        // sprite x,y 初始位置
        sprite: {
          x: null,
          y: null,
          scale: 1
        },
        // scale x,y 按钮初始位置 x2,y2 原点
        scale: {x: null, y: null, x2: null, y2: null},
        //rotate 旋转按钮 初始的位置
        rotate: {x: null, y: null},
        container: {x: null, y: null},
        deg: 0
      },

      resources: null, //资源加载

      operateType: '',//操作状态
      //范围点位信息
      addFrom: {
        biaodian: [],
        graphics: null,//多边形dom
      },
      //范围顶点信息
      circularSeat: [],
      squareCallout: 1,
      newDaBiaoDian: [],
      keyWorld: '',
    }
  },

  mounted() {
    this.refreshList(1)
    //创建 PIXI 渲染器 函数
    this.createStickerCanvas()
    this.$axios(this.api.original.dyygmodelGetDyygModel, {
      modelState: 0,
      keyWord: '',
      current: 1,
      size: 99,
    }, 'post').then(res => {
      if (res.status) {
        this.modelLabelList = res.data.records
      }
    })
  },

  methods: {
    getModelIdName(id) {
      let row = this.modelLabelList.filter(item => {
        return item.id == id
      })
      if (row.length) {
        return row[0].modelName
      }
    },

    // 获取数据列表
    refreshList(type) {
      if (type == 1) {
        this.pageNo = 1
      }
      this.id = this.$route.query.id
      this.loading = true
      this.$axios(this.api.original.dyygmodelGetById + this.id, {}, 'get').then((res) => {
        if (res.status) {
          this.loading = false
          this.inputForm = res.data
          this.dataList = res.data.modelResources
          if (this.dataList.length) {
            // this.selImg = this.dataList[0]
            let seData
            if (this.selImg && this.selImg.id) {
              seData = this.dataList.filter(item => {
                return item.id == this.selImg.id
              })
            }
            this.selImg = seData ? seData[0] : this.dataList[0]
            this.$nextTick(() => {
              this.selRow(this.selImg);
            })
          }
          this.total = parseInt(res.data.total)
        }
      })
    },

    // 选择行
    selRow(row) {
      console.log(row)
      this.imgEditType = false
      this.selImg = row
      if (this.circularList) {
        this.noStartAnnotation()
      }
      if (this.container) {
        this.app.stage.removeChild(this.container)
        this.sprite = null
        this.container = null
      }

      this.manifest[0].url = this.selImg.inputResource
      //加载纹理
      this.loadTexture()
      setTimeout(() => {
        this.seeFindByImageId(row)
      }, 200)
      if (row) {
        this.$refs.table.setCurrentRow(row, true);  // 高亮某一行
      } else {
        this.$refs.table.setCurrentRow();  // 取消高亮
      }
    },

    //获取图片标注
    seeFindByImageId(row) {
      this.circularSeat = []
      this.creatList()
      this.createCircularList()
      console.log(row)
      if (row.outResource) {
        this.addFrom.biaodian = row.outResource.split(',').map(item => {
          return item * 1
        })
        this.addFrom.biaodian.forEach((item, index) => {
          if (index % 2 == 0) {
            this.circularSeat.push({
              x: item,
              y: this.addFrom.biaodian[index + 1]
            })
          }
        })
        this.createArea()
        this.createCircularItem()
      }


      // this.$axios(this.api.topic.findByImageId, {
      //     imageId: this.selImg.id
      // }, 'get').then(data => {
      //     this.circularSeat = []
      //     this.creatList()
      //     this.createCircularList()
      //     this.addFrom.biaodian = data.data.xzAxis.split(',').map(item => {
      //         return item * 1
      //     })
      //     this.addFrom.biaodian.forEach((item, index) => {
      //         if (index % 2 == 0) {
      //             this.circularSeat.push({
      //                 x: item,
      //                 y: this.addFrom.biaodian[index + 1]
      //             })
      //         }
      //     })
      //     this.createArea()
      //     this.createCircularItem()
      // })
    },

    machineLabeling() {
      if (this.keyWorld == '') {
        this.$message.error('机器标注请输入关键字')
        return
      }
      // this.$axios(this.api.topic.semiAutomaticLabeling, {
      //     imgkey: this.selImg.id,
      //     remarks: this.keyWorld,
      //     serviceType: this.row.category - 2,
      // }, 'post').then(data => {
      //     if (data.status) {
      //         if (data.data.data.posdatas.length) {
      //             this.noStartAnnotation()
      //             this.$nextTick(() => {
      //                 this.creatList()
      //                 this.createCircularList()
      //                 this.imgEditType = true
      //                 this.addFrom.biaodian = []
      //                 this.circularSeat = data.data.data.posdatas[0]
      //                 data.data.data.posdatas[0].forEach(item => {
      //                     this.addFrom.biaodian.push(item.x)
      //                     this.addFrom.biaodian.push(item.y)
      //                 })
      //                 this.createArea()
      //                 this.createCircularItem()
      //                 this.$message.success("机器标注成功")
      //             })
      //         } else {
      //             this.$message.error("暂无标注数据")
      //         }
      //     } else {
      //         this.$message.error("标注失败")
      //     }
      // })
    },

    // 提交审核
    submitAudit() {
      this.$confirm('确认提交审核？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        // this.$axios(this.api.topic.imagesUpdateById, {
        //     id: this.selImg.id,
        //     sbState: 1,
        // }, 'post').then(data => {
        //     if (data && data.status) {
        //         this.$message.success('提交成功');
        //         this.refreshList()
        //     } else {
        //         this.$message.error('提交失败');
        //     }
        // })
      })
    },

    // 保存标注
    saveDimension() {
      if (this.circularSeat.length != 0) {
        this.$axios(this.api.original.dyygmodelresourceUpdateById, {
          id: this.selImg.id,
          outResource: this.addFrom.biaodian.join(',')
        }, 'put').then(res => {
          if (res.status) {
            this.noStartAnnotation()
            this.$message.success('标注成功')
            // this.seeFindByImageId()
            this.refreshList()
          } else {
            this.$message.error('标注失败')
          }
        })
      } else {
        this.$message.warning('暂未标注')
      }
    },

    // 查看详情
    view() {
      if (this.selImg.sbState == 2 || this.selImg.sbState == 3) {
        this.$alert(this.selImg.sbInfo, '专家意见', {
          showConfirmButton: false
        });
      } else {
        this.$alert('请完成标注后提交审核', '专家意见', {
          showConfirmButton: false
        });
      }
    },

    //取消标注 type是否需要重新加载标注
    noStartAnnotation(type) {
      this.imgEditType = false
      this.operateType = ''
      this.sprite.removeChild(this.circularList)
      this.sprite.removeChild(this.containerList)
      this.containerList = null
      this.circularList = null
      this.circularSeat = []
      this.addFrom = {
        biaodian: [],
        graphics: null,//多边形dom
      }
      if (type) {
        this.refreshList()
      }
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },

    //标注
    startAnnotation(num) {
      this.squareCallout = num
      if (this.circularSeat.length != 0) {
        this.noStartAnnotation()
      }
      if (num == 1) {
        this.creatList()
        this.createCircularList()
        this.$message.warning('请在图片上最少标三个点')
      } else {
        this.creatList()
        this.createCircularList()
        this.addFrom.biaodian = [200, 200, 600, 200, 600, 600, 200, 600]
        this.circularSeat = [
          {
            x: 200,
            y: 200,
          },
          {
            x: 600,
            y: 200,
          },
          {
            x: 600,
            y: 600,
          },
          {
            x: 200,
            y: 600,
          },

        ]
        this.createArea(2)
        this.createCircularItem()
      }
      this.operateType = 'dimension'
      this.imgEditType = true
    },

    // 创建 PIXI 渲染器 函数
    createStickerCanvas() {
      //获取画布父容器dom
      this.canvasFather = this.$refs.page_canvas
      // 创建一个pixi渲染器
      this.app = new PIXI.Application({
        backgroundColor: 0xffffff,
        // transparent: true,// 透明
        antialias: true,
        width: this.canvasFather.clientWidth,
        height: this.canvasFather.clientHeight,
        resizeTo: this.canvasFather
      })
      // 把pixi渲染器 放到页面page_canvas元素中
      this.canvasFather.appendChild(this.app.view)
    },


    //loader 加载图片资源到纹理缓存函数
    loadTexture() {
      let loader = new PIXI.loaders.Loader()
      loader.add(this.manifest)
      loader.on('progress', () => {
        console.log('加载完成')
      })
      loader.on('progress', (loader, resource) => {
        // console.log("loading: " + resource.url);
        console.log('progress: ' + loader.progress + '%') //加载进度
      })

      loader.load((e) => {
        this.resources = e.resources
        //将地图纹理添加进容器
        this.manifest.forEach((item) => {
          this.getImgSize(item)
        })
      })
    },

    //获取图片的原始宽高
    getImgSize(item) {
      let that = this
      let img = new Image()
      img.src = item.url
      if (item.name == 'mapUrl') {
        //地图原始比例
        img.onload = function () {
          that.imgWidth = img.width
          that.imgHeight = img.height
          that.createPIXIImg(item.name)
        }
      }
    },

    // sprite container 函数
    createPIXIImg(name) {
      // 创建 container
      var container = new PIXI.Container()
      container.scale.set(1, 1)
      container.rotation = 0
      container.name = name
      this.container = container
      // 创建sprite
      var sprite = new PIXI.Sprite(this.resources[name].texture)
      this.sprite = sprite

      //设置底部图片的大小
      if (this.imgWidth / this.imgHeight > this.canvasFather.clientWidth / this.canvasFather.clientHeight) {
        //图片比底部图宽 宽铺满
        sprite.width = this.canvasFather.clientWidth
        sprite.height = this.imgHeight * this.canvasFather.clientWidth / this.imgWidth
      } else {
        //图片比底部图 高铺满
        sprite.width = this.imgWidth * this.canvasFather.clientHeight / this.imgHeight
        sprite.height = this.canvasFather.clientHeight
      }
      this.imgNewWidth = sprite.width
      this.imgNewHeight = sprite.height

      // sprite设置定位
      sprite.position.set(0, 0)

      // 设置锚点在中间
      sprite.anchor.set(0, 0)

      // 设置有交互性
      sprite.interactive = true
      sprite.name = name

      //  设置sprite 点击事件
      sprite.on('pointerdown', (e) => {
        // console.log('点击按钮按下')
        // this.onDragStart(e)
      })
      sprite.on('pointerup', (e) => {
        // console.log('点击按钮抬起')
        this.onDragEnd(e)
      })
      sprite.on('pointerupoutside', (e) => {
        // console.log('超出范围的抬起')
        // this.onDragEnd(e)
      })
      sprite.on('pointermove', (e) => {
        //鼠标移动
        // this.onDragMove(e)
      })
      // this.seeFindByImageId(this.selImg)

      // 把sprite 添加到container中
      container.addChild(sprite)
      // 把container 添加到 pixi 渲染器中
      this.app.stage.addChild(container)
    },

    //创建圈定范围合集
    creatList(num) {
      // 创建 container 区域合集
      let containerList = new PIXI.Container()
      containerList.scale.set(1, 1)
      containerList.rotation = 0
      containerList.name = 'name'
      this.containerList = containerList
    }
    ,

//创建圆形合集
    createCircularList() {
      // 创建 container 区域合集
      let circularList = new PIXI.Container()
      circularList.scale.set(1, 1)
      circularList.rotation = 0
      circularList.name = 'Circular'
      this.circularList = circularList
    }
    ,

    onDragEnd(e) {
      //点击图片时
      if (this.operateType == 'dimension' && this.squareCallout != 2) {
        this.canvasFatherLeft = this.canvasFather.getBoundingClientRect().left
        this.canvasFatherTop = this.canvasFather.getBoundingClientRect().top
        // 鼠标在图片上点击的位置 =  (鼠标的在距离屏幕左边的位置 - 容器距离左边屏幕的位置 - 该图片在x轴拖动的距离) / 画布缩放的大小
        let x = (e.data.originalEvent.clientX - this.canvasFatherLeft - this.sprite.x) / this.sprite.scale.x
        let y = (e.data.originalEvent.clientY - this.canvasFatherTop - this.sprite.y) / this.sprite.scale.y
        setTimeout(() => {
          //储存点位信息，用来画范围
          this.addFrom.biaodian.push(x)
          this.addFrom.biaodian.push(y)
          if (this.addFrom.biaodian.length >= 6) {
            this.createArea()
          }

          //储存顶点信息，告诉他点的位置在哪里
          this.circularSeat.push({
            x: x,
            y: y,
            graphics: null,
          })
          this.createCircularItem()
        })
      }

      if (this.operateType == 'scale' || this.operateType == 'graphicalMove') {
        this.onRegionEnd();
      }
    }
    ,

    createArea(num) {
      if (this.addFrom.graphics) {
        this.containerList.removeChild(this.addFrom.graphics)
      }
      if (this.scaleBtn) {
        this.containerList.removeChild(this.scaleBtn)
      }
      this.addFrom.graphics = new PIXI.Graphics()
      this.addFrom.graphics.beginFill(0xD2B48C)
      this.addFrom.graphics.drawPolygon(this.addFrom.biaodian)
      this.addFrom.graphics.endFill()
      this.addFrom.graphics.alpha = 0.4
      this.addFrom.graphics.interactive = true;
      this.addFrom.graphics.name = 'ajbioaid'
      if (num == 2) {
        //移动整个图形
        this.addFrom.graphics.on("pointerdown", (e) => {
          this.onRegionStart(e);
        });
        this.addFrom.graphics.on("pointerup", (e) => {
          this.onRegionEnd(e);
        });
        this.addFrom.graphics.on("pointerupoutside", (e) => {
          this.onRegionEnd(e);
        });
        this.addFrom.graphics.on("pointermove", (e) => {
          this.onRegionMove(e);
        });

        //添加缩放按钮
        this.scaleBtn = new PIXI.Sprite(this.resources["scale_btn"].texture);
        this.scaleBtn.name = "scale_btn";
        this.scaleBtn.interactive = true;
        this.scaleBtn.buttonMode = true;  // 设置鼠标悬停时变成小手形状
        this.scaleBtn.anchor.set(0.5);
        this.scaleBtn.width = 50
        this.scaleBtn.height = 50
        this.scaleBtn.position.set(this.addFrom.biaodian[2], this.addFrom.biaodian[3])
        this.scaleBtn.alpha = 1;
        this.containerList.addChild(this.scaleBtn)

        // 设置缩放
        this.scaleBtn.on("pointerdown", (e) => {
          this.onScaleButStart(e);
        });
        this.scaleBtn.on("pointerup", (e) => {
          this.onRegionEnd(e);
        });
        this.scaleBtn.on("pointerupoutside", (e) => {
          this.onRegionEnd(e);
        });
        this.scaleBtn.on("pointermove", (e) => {
          this.onScaleButMove(e);
        });


      }
      this.containerList.addChild(
          this.addFrom.graphics
      )

      // 把container 添加到 pixi 渲染器中
      this.sprite.addChild(this.containerList)
    },

//创建圆形点位
    createCircularItem() {
      this.circularSeat.forEach(item => {
        if (!item.graphics) {
          let circle = new PIXI.Graphics();
          circle.beginFill(0xFFFF00);
          circle.drawCircle(item.x, item.y, 6);
          circle.endFill();
          this.circularList.addChild(circle)
        }
      })
      // 把container 添加到 pixi 渲染器中
      this.sprite.addChild(this.circularList)
    }
    ,


// 点击缩放按钮 函数
    onScaleButStart(e) {
      this.operateType = "scale";
      this.sprite.removeChild(this.circularList)
      this.circularList = null
      this.circularSeat = []
    }
    ,
// 移动缩放按钮 函数
    onScaleButMove(e) {
      if (this.operateType === "scale") {
        this.canvasFatherLeft = this.canvasFather.getBoundingClientRect().left
        this.canvasFatherTop = this.canvasFather.getBoundingClientRect().top
        // 鼠标在图片上的位置 =  (鼠标的在距离屏幕左边的位置 - 容器距离左边屏幕的位置 - 该图片在x轴拖动的距离) / 画布缩放的大小
        let x = (e.data.originalEvent.clientX - this.canvasFatherLeft - this.sprite.x) / this.sprite.scale.x
        let y = (e.data.originalEvent.clientY - this.canvasFatherTop - this.sprite.y) / this.sprite.scale.y
        let moveX = x - this.addFrom.biaodian[2]
        let sideLengthX = this.addFrom.biaodian[4] + moveX
        let sideLengthY = this.addFrom.biaodian[5] + moveX

        //范围判定
        if (sideLengthY > this.imgHeight) {
          //底部到达边界
          sideLengthX = sideLengthX - (sideLengthY - this.imgHeight)
          sideLengthY = this.imgHeight
        }
        if (sideLengthX > this.imgWidth) {
          //右边到达边界
          sideLengthY = sideLengthY - (sideLengthX - this.imgWidth)
          sideLengthX = this.imgWidth
        }
        if (sideLengthY < 0) {
          //上边到达边界
          if (this.addFrom.biaodian[5] == 0) return
          sideLengthX = sideLengthX + sideLengthY
          sideLengthY = 0
        }
        if (sideLengthX < 0) {
          //左边到达边界
          if (this.addFrom.biaodian[4] == 0) return
          sideLengthY = sideLengthY + sideLengthX
          sideLengthX = 0
        }

        this.addFrom.biaodian[2] = sideLengthX
        this.addFrom.biaodian[4] = sideLengthX
        this.addFrom.biaodian[5] = sideLengthY
        this.addFrom.biaodian[7] = sideLengthY

        this.scaleBtn.position.set(this.addFrom.biaodian[2], this.addFrom.biaodian[3])

        this.circularSeat = []

        this.addFrom.biaodian.forEach((item, index) => {
          if (index % 2 == 0) {
            this.circularSeat.push({
              x: this.addFrom.biaodian[index],
              y: this.addFrom.biaodian[index + 1],
              graphics: null,
            })
          }
        })
        this.createArea(2)
      }
    }
    ,

    onRegionStart(e) {
      if (this.operateType != "scale") {
        this.operateType = 'graphicalMove'
        this.newDaBiaoDian = JSON.parse(JSON.stringify(this.addFrom.biaodian))
        this.startPoint.x = e.data.originalEvent.clientX;
        this.startPoint.y = e.data.originalEvent.clientY;
        this.startPoint.sprite.x = this.sprite.x;
        this.startPoint.sprite.y = this.sprite.y;

        this.sprite.removeChild(this.circularList)
        this.circularList = null
        this.circularSeat = []
      }
    }
    ,

    onRegionEnd(e) {
      this.operateType = "";
      this.newDaBiaoDian = JSON.parse(JSON.stringify(this.addFrom.biaodian))
      this.createCircularList()
      this.createCircularItem()
    }
    ,

    onRegionMove(e) {
      if (this.operateType === 'graphicalMove') {
        this.canvasFatherLeft = this.canvasFather.getBoundingClientRect().left
        this.canvasFatherTop = this.canvasFather.getBoundingClientRect().top
        let oldX = (this.startPoint.x - this.canvasFatherLeft - this.sprite.x) / this.sprite.scale.x
        let oldY = (this.startPoint.y - this.canvasFatherTop - this.sprite.y) / this.sprite.scale.y

        // 鼠标在图片上点击的位置 =  (鼠标的在距离屏幕左边的位置 - 容器距离左边屏幕的位置 - 该图片在x轴拖动的距离) / 画布缩放的大小
        let x = (e.data.originalEvent.clientX - this.canvasFatherLeft - this.sprite.x) / this.sprite.scale.x
        let y = (e.data.originalEvent.clientY - this.canvasFatherTop - this.sprite.y) / this.sprite.scale.y

        let moveX = x - oldX
        let moveY = y - oldY

        this.addFrom.biaodian = JSON.parse(JSON.stringify(this.newDaBiaoDian))

        //移动边界判断
        //左边触发边界
        let lengthX = this.addFrom.biaodian[2] - this.addFrom.biaodian[0]
        let leftX = this.addFrom.biaodian.filter((item, index) => {
          if (index % 2 == 0) {
            //x轴
            return item + moveX < 0
          }
        })

        //右边触发边界
        let rightX = this.addFrom.biaodian.filter((item, index) => {
          if (index % 2 == 0) {
            //x轴
            return item + moveX > this.imgWidth
          }
        })

        let lengthY = this.addFrom.biaodian[5] - this.addFrom.biaodian[3]
        let topY = this.addFrom.biaodian.filter((item, index) => {
          if (index % 2 == 1) {
            //x轴
            return item + moveY < 0
          }
        })

        let bottomY = this.addFrom.biaodian.filter((item, index) => {
          if (index % 2 == 1) {
            //x轴
            return item + moveY > this.imgHeight
          }
        })


        for (let i = 0; i < this.addFrom.biaodian.length; i++) {
          if (i % 2 == 0) {
            //x轴
            if (leftX.length > 0) {
              if (this.addFrom.biaodian[0] < this.addFrom.biaodian[2]) {
                this.addFrom.biaodian[0] = 0
                this.addFrom.biaodian[6] = 0
                this.addFrom.biaodian[2] = lengthX
                this.addFrom.biaodian[4] = lengthX
              } else {
                this.addFrom.biaodian[2] = 0
                this.addFrom.biaodian[4] = 0
                this.addFrom.biaodian[0] = -lengthX
                this.addFrom.biaodian[6] = -lengthX
              }
            } else if (rightX.length > 0) {
              if (this.addFrom.biaodian[0] < this.addFrom.biaodian[2]) {
                this.addFrom.biaodian[2] = this.imgWidth
                this.addFrom.biaodian[4] = this.imgWidth
                this.addFrom.biaodian[0] = this.imgWidth - lengthX
                this.addFrom.biaodian[6] = this.imgWidth - lengthX
              } else {
                this.addFrom.biaodian[0] = this.imgWidth
                this.addFrom.biaodian[6] = this.imgWidth
                this.addFrom.biaodian[2] = this.imgWidth + lengthX
                this.addFrom.biaodian[4] = this.imgWidth + lengthX
              }
            } else {
              this.addFrom.biaodian[i] += moveX
            }
          } else {
            if (topY.length > 0) {
              if (this.addFrom.biaodian[3] < this.addFrom.biaodian[5]) {
                this.addFrom.biaodian[1] = 0
                this.addFrom.biaodian[3] = 0
                this.addFrom.biaodian[5] = lengthY
                this.addFrom.biaodian[7] = lengthY
              } else {
                this.addFrom.biaodian[1] = -lengthY
                this.addFrom.biaodian[3] = -lengthY
                this.addFrom.biaodian[5] = 0
                this.addFrom.biaodian[7] = 0
              }
            } else if (bottomY.length > 0) {
              if (this.addFrom.biaodian[3] < this.addFrom.biaodian[5]) {
                this.addFrom.biaodian[1] = this.imgHeight - lengthY
                this.addFrom.biaodian[3] = this.imgHeight - lengthY
                this.addFrom.biaodian[5] = this.imgHeight
                this.addFrom.biaodian[7] = this.imgHeight
              } else {
                this.addFrom.biaodian[1] = this.imgHeight
                this.addFrom.biaodian[3] = this.imgHeight
                this.addFrom.biaodian[5] = this.imgHeight + lengthY
                this.addFrom.biaodian[7] = this.imgHeight + lengthY
              }
            } else {
              this.addFrom.biaodian[i] += moveY
            }
          }
        }

        this.scaleBtn.position.set(this.addFrom.biaodian[2], this.addFrom.biaodian[3])

        this.circularSeat = []

        this.addFrom.biaodian.forEach((item, index) => {
          if (index % 2 == 0) {
            this.circularSeat.push({
              x: this.addFrom.biaodian[index],
              y: this.addFrom.biaodian[index + 1],
              graphics: null,
            })
          }
        })
        this.createArea(2)
      }
    },

    returnPage() {
      this.$router.back()
    },
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.labelImg {
  height: calc(100vh - 360px);
  width: 100%;
  margin-bottom: 10px;
}
</style>
